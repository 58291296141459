import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Input from '@frontend/ui-kit/Components/Input';
import DatePicker from '@frontend/ui-kit/Components/DatePicker';
import Checkbox from '@frontend/ui-kit/Components/Checkbox';
import Separator from '@frontend/ui-kit/Components/Separator';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import FileUploader from '../../shared/FileUploader';
import {Field} from '../../shared/FormComponents';
import MemberServices, {validate as validateMemberService} from './MemberServices';
import EligibilityInformation from './EligibilityInformation';
import useFormState from '../../../hooks/useFormState';
import {formatDate, validateRequired} from '../../../utils';
import {ACCEPTABLE_IMAGE_FORMATS, DEFAULT_PHONE_PLACEHOLDER} from '../../../constants';
import './index.scss';

const BACKSIDE_IMAGE_MAX_SIZE = 1;

const validate = values => ({
    ...validateMemberService(values),
    concierge_support_phone_number: validateRequired(values.concierge_support_phone_number)
});

const Platform = ({isEditDisabled}) => {
    const [isLaunchDateDisabled, setIsLaunchDateDisabled] = useState(true);
    const {values} = useFormState();

    return (
        <div className='app-configuration-content'>
            <ContentSection className='app-configuration-content__section'>
                <Text className='app-configuration-content__title' type={TEXT_TYPES.bodyBold}>General Information</Text>

                <Row>
                    <Column sm={4}>
                        <Checkbox value={values.is_real} caption='Is Real' disabled/>
                    </Column>
                    <Column sm={4}>
                        <Checkbox value={values.group_launched} caption='Group Launched' disabled/>
                    </Column>
                    <Column sm={4}>
                        <Checkbox value={values.banned} caption='Banned/Terminate' disabled/>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={4}>
                        <Field name='title'>
                            {props => <Input {...props} placeholder='' disabled={isEditDisabled} label='Title'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={values.alias} placeholder='' description='This field is automatically populated from Company Manager' disabled label='Alias'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={values.client_identity} placeholder='[company_name_multiple_instances]' description='If needed, reach out to the DEV team to manually set the field' disabled label='Client identity'/>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={4}>
                        <Field name='launch_date'>
                            {props => (
                                <DatePicker {...props}
                                    disabled={isEditDisabled || isLaunchDateDisabled}
                                    label='Launch Date'
                                    description={!isEditDisabled && isLaunchDateDisabled && (
                                        <div className='interactive-description'>
                                            <Button className='interactive-description__button'
                                                data-testid='toggle-launch-date-button'
                                                type={BUTTON_TYPES.tertiary}
                                                onClick={() => setIsLaunchDateDisabled(isLaunchDateDisabled => !isLaunchDateDisabled)}>
                                                Edit Launch Date
                                            </Button>
                                        </div>
                                    )}/>
                            )}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.termination_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is automatically populated from Company Manager' disabled label='Client Termination Date'/>
                    </Column>
                    <Column sm={4}>
                        <Input value={formatDate(values.benefits_plan_renewal_date, 'M/d/yyyy')} placeholder='mm/dd/yyyy' description='This field is populated from Salesforce' disabled label='Benefit Plan Renewal Date'/>
                    </Column>
                </Row>

                <Separator/>

                <Row className='mb-13'>
                    <Column sm={4}>
                        <Input value={values.salesforce_id} placeholder='' description='This field is automatically populated from Company Manager' disabled label='Salesforce Account ID'/>
                    </Column>
                    <Column sm={4}>
                        <Field name='knowledge_base_url'>
                            {props => <Input {...props} disabled={isEditDisabled} placeholder='www.link.com' label='HCC Knowledge Base URL'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='logo_uri'>
                            {props => <Input {...props} disabled={isEditDisabled} placeholder='https://Logo' label='Logo'/>}
                        </Field>
                    </Column>
                </Row>

                <Row>
                    <Column sm={4}>
                        <Field name='concierge_support_phone_number'>
                            {props => <Input {...props} type='tel' disabled={isEditDisabled} placeholder={DEFAULT_PHONE_PLACEHOLDER} label='Concierge Support Number'/>}
                        </Field>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={4}>
                        <Field name='sms_allowed'>
                            {props => <Checkbox {...props} className='checkbox-middle' disabled={isEditDisabled} caption='SMS Allowed'/>}
                        </Field>
                    </Column>
                </Row>
            </ContentSection>

            <EligibilityInformation isEditDisabled={isEditDisabled}/>

            {/*<ContentSection className='app-configuration__content-section'>*/}
            {/*    <Text className='app-configuration__title' type={TEXT_TYPES.bodyBold}>Open Enrollment Information</Text>*/}

            {/*    <Row>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Start Date' type='datePicker'>*/}
            {/*                <Field name='oe_start_date'>*/}
            {/*                    {props => <DatePicker {...props} disabled={isEditDisabled} isClearable/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE End Date' type='datePicker'>*/}
            {/*                <Field name='oe_end_date'>*/}
            {/*                    {props => <DatePicker {...props} disabled={isEditDisabled} isClearable/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='Plan Year Start Date' type='datePicker'>*/}
            {/*                <Field name='plan_year_start_date'>*/}
            {/*                    {props => <DatePicker {...props} disabled={isEditDisabled} isClearable/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*    </Row>*/}

            {/*    <Separator/>*/}

            {/*    <Row>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='Education Materials URL'>*/}
            {/*                <Field name='education_materials_url'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder='www.link.com'/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='Plan Options URL'>*/}
            {/*                <Field name='plan_options_url'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder='www.link.com'/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='Enrollment URL'>*/}
            {/*                <Field name='enrollment_url'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder='www.link.com'/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*    </Row>*/}

            {/*    <Separator/>*/}

            {/*    <Row>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Info'>*/}
            {/*                <Field name='oe_info'>*/}
            {/*                    {props => <Textarea {...props} disabled={isEditDisabled} placeholder='Type Text...'/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Benefits Changes'>*/}
            {/*                <Field name='oe_benefit_changes'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder=''/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Benefits Changes URL'>*/}
            {/*                <Field name='oe_benefit_changes_url'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder='www.link.com'/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*    </Row>*/}

            {/*    <Separator/>*/}

            {/*    <Row>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Support Phone'>*/}
            {/*                <Field name='oe_support_phone'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder=''/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Support Email'>*/}
            {/*                <Field name='oe_support_email'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder=''/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*        <Column sm={4}>*/}
            {/*            <Label label='OE Support URL'>*/}
            {/*                <Field name='oe_support_url'>*/}
            {/*                    {props => <Input {...props} disabled={isEditDisabled} placeholder='www.link.com'/>}*/}
            {/*                </Field>*/}
            {/*            </Label>*/}
            {/*        </Column>*/}
            {/*    </Row>*/}

            {/*    <Separator/>*/}

            {/*    <Row>*/}
            {/*        <Column sm={12}>*/}
            {/*            <Field name='outbounds_enabled'>*/}
            {/*                {props => <Checkbox {...props} disabled={isEditDisabled} caption='Outbounds Enabled'/>}*/}
            {/*            </Field>*/}
            {/*        </Column>*/}
            {/*    </Row>*/}
            {/*</ContentSection>*/}

            <ContentSection className='app-configuration-content__section'>
                <Text className='app-configuration-content__title' type={TEXT_TYPES.bodyBold}>Miscellaneous App Customizations</Text>

                <Row>
                    <Column sm={4}>
                        <Field name='is_supported_financial_accounts'>
                            {props => <Checkbox {...props} disabled={isEditDisabled} caption='Financial Accounts Supported'/>}
                        </Field>
                    </Column>
                    <Column sm={4}>
                        <Field name='is_supported_benefits_guidance'>
                            {props => <Checkbox {...props} disabled={isEditDisabled} caption='Benefits Guidance Supported'/>}
                        </Field>
                    </Column>
                </Row>

                <Separator/>

                <Row>
                    <Column sm={6}>
                        <Field name='medical_wallet_back_side'>{props => (
                            <FileUploader {...props}
                                className='backside-wallet-uploader'
                                disabled={isEditDisabled}
                                accept={ACCEPTABLE_IMAGE_FORMATS}
                                maxSize={BACKSIDE_IMAGE_MAX_SIZE}
                                isImage
                                label='Back Side of Medical Wallet'/>
                        )}
                        </Field>
                    </Column>
                </Row>
            </ContentSection>

            <MemberServices isEditDisabled={isEditDisabled}/>
        </div>
    );
};

Platform.propTypes = {
    isEditDisabled: PropTypes.bool.isRequired
};

export {validate, Platform as TestablePlatform};
export default React.memo(Platform);
